<template>    
    <v-form @submit.prevent="'true'" ref="form">
        <v-row dense>
            <v-col>
                <TextField label="E-mail" :dense="!true"
                    prepend-inner-icon="mdi-account"
                    :rules="[v => !!v || 'E-mail is verplicht']" 
                    v-model="email" required>
                </TextField>
            </v-col>
        </v-row>

        <v-row dense>
            <Button type="submit" :loading="user.isLoading" block main primary large @click="onForgotPassword">Reset Wachtwoord</Button>
        </v-row>
        <v-row dense>
            <v-col align="right" justify="right">
                <slot name="links">
                    <router-link to="/login" class="text-sm base-link">
                        Terug naar inloggen
                    </router-link>
                </slot>
            </v-col>
        </v-row>

    </v-form>

</template>

<script setup>
    import user from '@app/user'
    import TextField from '@controls/input/TextField'
    import PasswordField from '@controls/input/PasswordField'
    import Button from '@controls/buttons/ActionButton'
    import eventbus from "@app/eventbus"
    import { ref } from 'vue'
    const form = ref(null)

//
// Usage: 
//
//   import ForgotPasswordForm from '@shared/ui/auth/ForgotPasswordForm'
//

    var email = ref("");
    var password = ref("");

    async function onForgotPassword() {

        var f = form?.value;
        if (!f||!f.validate||!f.validate()) {
            return false;
        }
        var result = await user.forgotPassword(email.value);
        if (!result.success) {
            eventbus.snackbar.error({text: result.message});
        } else {
            eventbus.snackbar.info({text: result.message});
        }
    }

</script>
