<template>
    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        top rounded
        :color="type"
    >
        {{ text }}

        <template v-slot:action="{ attrs }">
            <v-btn
                light primary
                xxdcolor="white"
                :timeout="timeout"
                text
                v-bind="attrs"
                @click="snackbar = false">
                <v-icon class="custom-color" color="white">mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>

/**
 * Import and render this component in the app.vue page.
 * 
 * To open this snackbar, call: eventbus.snackbar.info({
 *    text: "Relaties zijn verwijderd", -- default: ""
 * });
 */
import component from '@lib/ui/component'
import eventbus from '@app/eventbus'

export default component.scaffold({
    name: 'Snackbar',
    components: {
    },
    data() {
        return {
            text: "",
            snackbar: false,
            timeout: 4000,
            type: 'default'
        }
    },
    registerEvents(self) {
        var on_event_bus_open = function(params) {
            params = params || {};
            self.text = params.text || "";
            self.type = params.type || "default";
            if (undefined != params.timeout) {
                self.timeout = params.timeout;
            }
            self.snackbar = true;
        };
        var on_event_bus_open_error = function(params) {
            params = params ||{};
            params.type = "error";
            on_event_bus_open(params);
        }
        return [
            eventbus.snackbar.info.on(on_event_bus_open),
            eventbus.snackbar.error.on(on_event_bus_open_error)

        ];
  },
})
</script>
